import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index/:id/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/guild',
    name: 'guild',
    component: () => import(/* webpackChunkName: "about" */ '../views/guild.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/search.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/proposal',
    name: 'proposal',
    component: () => import(/* webpackChunkName: "about" */ '../views/proposal.vue')
  },
  {
    path:'/:id/',
    redirect:{name:'index'}
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  console.log(to,"222")
  console.log(location)
  if(to.params.id != 'proposal' && to.name == 'index'){
    localStorage.setItem('cloudAllianceId',to.params.id)
  }
  next()
})
export default router
