<template>
  <div id="app">
    <div id="nav">
      <header_nav></header_nav>
    </div>
    <keep-alive include="index">
      <router-view/>
    </keep-alive>
    <div id="tail">
      <tail_nav></tail_nav>
    </div>
  </div>
</template>
<script>
import header_nav from "@/components/header_nav";
import tail_nav from "@/components/tail_nav";
export default {
  components:{
    header_nav,tail_nav
  },
  created(){
    console.log(location,"111")
  }
}
</script>
<style lang="scss">
body{
  margin: 0;
  min-width: 1200px;
}
</style>
