<template>
  <div class="box">
    <div class="container_box">
      <div>
        {{list.copyright}} {{list.cloudAllianceName}} | <span class="goto" @click="goTo">管理入口</span>
      </div>
      <div>地址：{{list.address}} | 邮编：{{list.zipCode}}</div>
      <div class="tail">
        <img :src="list.imgUrl" @click="goToBei">
        <a style="color: #F08407;text-decoration: none;margin-left: 4px" href="https://beian.miit.gov.cn/" target="_blank">{{list.filingsTitle}}</a>
      </div>
    </div>

  </div>
</template>

<script>
import {getPublicList} from "@/api/api";

export default {
  name: "tail_nav",
  data(){
    return{
      list:{}
    }
  },
  created(){
    getPublicList({cloudAllianceId:localStorage.getItem('cloudAllianceId')}).then(res=>{
      console.log(res,"dibu")
      this.list = res.rows[0]
    })
  },
  methods:{
    goTo(){
      window.open(this.list.backendLink)
    },
    goToBei(){
      window.open(this.list.imgLink)
    }
  }
}
</script>

<style scoped lang="scss">
.box{
  // position: absolute;
  bottom: 0;
  width: 100%;
  background: #800000;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .container_box{
    width: 1200px;
    color: white;
    div{
      margin-bottom: 20px;
    }
  }
}
.tail{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  img{
    cursor: pointer;
    margin-left: 14px;
    width: 18px;
    margin-right: 2px;
  }
}
.goto:hover{
  cursor: pointer;
}
</style>
