import request from "@/config/request.js";

/**
 * 查询菜单
 *
*/
export function getMenuListTree(params)
{
  return request.get("public/website/tabWebsiteMenu/listTree",{params});
}
/*
* 查询菜单详情
* */
export function getTabWebsiteMenuId(id)
{
  return request.get("public/website/tabWebsiteMenu/"+id);
}
/*
* 获取官网文章列表
* */
export function getWebsiteMenuList(params)
{
  return request.get("public/website/tabWebsiteArticle/listPreview",{params});
}
/*
* 获取官网文章详细信息
* */
export function getPublicTabWebsiteArticleId(id)
{
  return request.get("public/website/tabWebsiteArticle/"+id);
}

// 获取官网文章列表
export function getTabWebsiteArticleList(params){
  return request.get('/public/website/tabWebsiteArticle/list',{params})
}
/*
* 官网-查询网站logo列表
* */
export function getWebsiteTabWebsiteLogoList(params){
  return request.get('public/website/tabWebsiteLogo/list',{params})
}
/*
* 官网-查询网站底部版权信息列表
* */
export function getPublicList(params){
  return request.get('public/website/tabWebsiteCopyright/list',{params})
}
/**
 * 获取验证码
 *
 */
export function getCaptchaImage()
{
  return request.get("captchaImage");
}
/**
 * 密码登录方法
 *
 */
export function getLogin(data)
{
  return request.post("login",data);
}

/**
 查询我的提案信息列表
 *
 */
export function getAllianceProposalMyList(params)
{
  return request.get("h5/alliance/proposal/MyList",{params});
}
/**
 * 新增提案信息
 *
 */
export function  postAllianceProposal(data)
{
  return request.post("h5/alliance/proposal",data);
}
/**
 * 获取提案信息详细信息
 *
 */
export function getAllianceProposalId(id)
{
  return request.get("h5/alliance/proposal/"+id);
}
// 查询网站轮播图列表
export function tabWebSiteCarouseImg(params){
  return request.get('public/website/tabWebsiteCarouselImg/list',{params})
}
