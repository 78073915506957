<template>
  <div class="app">
    <div class="header">
      <div class="left">
        <img :src="logoList.logoUrl">
      </div>
      <div class="right">
       <div class="right_text">
         <img src="../static/img/shouye.png">
         <span class="title">{{logoList.cloudAllianceName}}</span>
       </div>
        <el-input placeholder="请输入关键词搜索" class="right_input" v-model="input2">
          <template slot="append">
            <div class="input_text" @click="goToSearch()">
              <img src="../static/img/sousuo.png">
            </div>
          </template>
        </el-input>
      </div>
    </div>
    <div class="header_nav">

      <div class="header_nav_text" v-for="(item,index) in list" :index="index"  @mouseover="mouseover(index)" @mouseleave="mouseleave(index)">
        <div class="header_nav_info" @click="goToInfo(item)">{{item.menuName}}</div>
        <div class="header_nav_height" ref="box1">
          <div class="header_nav_child" @click="goToInfo(item,indexs)" v-for="(items,indexs) in item.children" :key="indexs">
            {{items.menuName}}
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import {getMenuListTree,getWebsiteTabWebsiteLogoList} from '@/api/api'
export default {
  name: "header_nav",
  data(){
    return{
      dialogVisible:false,
      isBtn:false,
      activeIndex:-1,
      input2:'',
      list:[],
      logoList:{
        logoUrl:'',
        cloudAllianceName:'',
        handLogoUrl:'',
        titleWebsite:''
      }
    }
  },
  mounted(){
    this.getMuList()
    getWebsiteTabWebsiteLogoList({cloudAllianceId:localStorage.getItem('cloudAllianceId')}).then(res=>{
      console.log(res,"logo")
      this.logoList = res.rows[0]
      localStorage.setItem('titleName',this.logoList.titleWebsite)
      localStorage.setItem('titleImg',this.logoList.handLogoUrl)

    })
  },
  methods:{
    mouseover(index){
      this.activeIndex = index
      this.$refs['box1'][index].style.height = this.$refs['box1'][index].scrollHeight + 'px'
    },
    mouseleave(index){
      this.activeIndex = -1
      this.$refs['box1'][index].style.background = '#AF0B13'
      this.$refs['box1'][index].style.height = 0
    },
    getMuList(){
      let params = {
        pageSize:999,
        cloudAllianceId:localStorage.getItem('cloudAllianceId'),
        orderByColumn:'orderSort',
        isAsc:'desc'
      }
      getMenuListTree(params).then(res=>{
        console.log(res,"res")
        this.list = res.rows
        if(this.list && this.list[0]){
          this.list[0].children = []
        }
      })
    },
    goToInfo(item,index){
      if(this.isBtn){
        this.$message({
          showClose: true,
          message: '点击过快',
          type: 'warning'
        });
        return
      }
      this.isBtn = true
      console.log(item,index)
      if(item.id == this.list[0].id){
        this.$router.push({ path: `/index/${localStorage.getItem('cloudAllianceId')}`}).catch(e=>e)
      }else {
        let childList = []
        if(index != undefined){
          childList = item.children[index]
        }else {
          if(item.children){
            childList = item.children[0]
          }
        }
        if(index == undefined && item.outsideLink == 1){
          window.open(item.menuUrl)
        }else if(childList.outsideLink == 1){
          window.open(childList.menuUrl)
        }else {
          this.$router.push({ path:'/guild' ,query:{id:item.id,childId:childList.id} }).catch(e=>e)
        }
      }
      setTimeout(()=>{
        this.isBtn = false
      },800)

    },
    goToSearch(){
      this.$router.push({ path:'/search' ,query:{text:this.input2} }).catch(e=>e)
      this.input2 = ''
    }
  }
}
</script>

<style scoped lang="scss">
.header{
  height: 18vh;
  background-image:  url(../static/img/beijing.png);
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .left{
    img{
      max-height: 100px;
    }
  }
  .right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .right_text{
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      img{
        width: 14px;
      }
      .title{
        margin-left: 4px;
        font-size: 14px;
      }
    }
    .right_input{
      cursor:pointer;
      .input_text{
        display: flex;
        align-items: center;
         img{
           margin: 0 20px;
            width: 30px;
        }
      }
      ::v-deep .el-input__inner{
        border: 1px solid #800000;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      ::v-deep .el-input-group__append{
        background: #800000;
        border: none;
        padding: 0;
      }
    }
  }
}
.header_nav{
  height: 50px;
  background: #800000;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  padding: 0 80px;
  .header_nav_text{
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    text-align: center;
    color: white;
    cursor: pointer;
    .header_nav_height{
      transition: height 0.8s;
      height: 0.01px;
      overflow: hidden;
      display: block;
      position: relative;
      font-size: 14px;
      z-index: 999;
      .header_nav_child{
        border-top: 1px solid white;
      }
    }
  }
  .header_nav_info{
    min-width: 130px;
  }
  .header_nav_text:hover{
    background: white;
    color: #800000;
    .header_nav_height{
      overflow: hidden;
      display: block;
      background: #AF0B13;
      .header_nav_child{

        color: white;
      }
    }
    .header_nav_child:hover{
      background: #800000;
      color: white;

    }
  }

}

</style>
