import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
const service = axios.create({
	baseURL: '/api',
	timeout: 60000 // 过期时间
})
//
service.interceptors.request.use(config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)
// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		if (res.code == 401) {
			localStorage.removeItem('token')
			location.reload()
			return Promise.reject(new Error(res.msg))
		} else if (res.code == 40001) {
			return res
		} else if (res.code != 200) {
			Message({
				message: res.msg || '网络开小差了，请稍后再试',
				type: 'error',
				duration: 3 * 1000
			})
			return Promise.reject(new Error(res.msg))
		} else {
			return res
		}

	},
	error => {
		console.log(error, 'rrr');
		Message({
			message: error.msg,
			type: 'error',
			duration: 3 * 1000
		})
		return Promise.reject(error)
	}
)

export default service
